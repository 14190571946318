import React from 'react';

import Layout from '../components/logic/layouts/Layout';
import Section from '../components/logic/layouts/Section';
import SEO from '../components/seo';

import StyledParagraph from '../components/styled/elements/StyledParagraph';
import StyledImg from '../components/styled/elements/StyledImg';
import StyledH1 from '../components/styled/elements/StyledH1';
import StyledH2 from '../components/styled/elements/StyledH2';
import StyledAnchor from '../components/styled/elements/StyledAnchor';
import StyledButton from '../components/styled/elements/StyledButton';
import StyledFlexBox from '../components/styled/layouts/StyledFlexBox';

import budgetTool from '../images/budget-tool.svg';

const SHEET_URL = 'https://docs.google.com/spreadsheets/d/1eK5ggQs9nb42JzechN0v-bf9WZ2up08SkK7IGkpCtq0';

const BudgetTool = () => (
  <Layout>
    <SEO title="Budgetverktyg" />
    <Section marginTop="none" marginBottom="none" background="lightGreen">
      <StyledFlexBox flexDirection="row">
        <StyledImg src={budgetTool} maxWidth="15rem" alt="" />
        <StyledFlexBox marginLeft="regular" flexDirection="column" padding="big" maxWidth="35rem">
          <StyledFlexBox textColour="primary" marginBottom="regular">
            <StyledH1 fontWeight="bolder">Budgetverktyg</StyledH1>
          </StyledFlexBox>
          <StyledParagraph textColour="primary" fontWeight="light" fontSize="small">
            Genom budgetverktyget får du en enkel översik över din privatekonomi
            vilket möjliggör insikter i balansen mellan inkomster och utgifter.
          </StyledParagraph>
        </StyledFlexBox>
      </StyledFlexBox>
    </Section>
    <Section marginTop="none" marginBottom="none">
      <StyledFlexBox textColour="primary" marginBottom="regular">
        <StyledH2>Varför är det viktigt att skapa en budget?</StyledH2>
      </StyledFlexBox>
      <StyledFlexBox maxWidth="55rem">
        <StyledParagraph fontWeight="light" fontSize="small">
          Genom att skapa en personlig budget får du möjlighet att optimera
          utgifterna och därigenom uppnå balans i din privatekonomi.
        </StyledParagraph>
      </StyledFlexBox>
    </Section>
    <Section marginTop="none" marginBottom="none" background="primary">
      <StyledFlexBox textColour="light" justifyContent="center" marginBottom="regular">
        <StyledH2>Kom igång!</StyledH2>
      </StyledFlexBox>
      <iframe height="800" width="100%" src={`${SHEET_URL}/edit?rm=minimal`} title="kalp-sheet" frameBorder="0" />
    </Section>
    <Section marginTop="none" narrow marginBottom="none">
      <StyledFlexBox alignItems="center" flexDirection="column">
        <StyledFlexBox textColour="primary" marginBottom="tiny">
          <StyledH2>Har du fyllt i din budget?</StyledH2>
        </StyledFlexBox>
        <StyledParagraph textAlign="center">
          Bra jobbat! Nu kan du alltid använda dig av din budget när du gör ekonomiska
          val i vardagen. Se till att ha budgeten nära till hands för att undersöka om
          det finns utrymme för specifika utgifter.
        </StyledParagraph>
        <StyledFlexBox marginTop="regular">
          <StyledButton background="primary" as={StyledAnchor} href={`${SHEET_URL}/export?format=pdf`}>Ladda ner</StyledButton>
        </StyledFlexBox>
      </StyledFlexBox>
    </Section>
  </Layout>
);

export default BudgetTool;
